.posted-date {
  color: $york;
  font-weight: $font-weight-medium;
  //text-transform: uppercase;
}

.authors-detail {
  p {
    margin: 0;
  }

  &__author {

    &__name {
      font-weight: $font-weight-semi;
    }
  }
}
